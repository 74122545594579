import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import resolveLink from '~utils/resolveLink'
import { Link } from 'gatsby'
import Image from '~components/Image'

const ProjectGridMobile = ({ data, bgColor }) => {

	return (
		<Wrap bgColor={bgColor}>
			{data?.projects?.map((project, i) => (
				<ProjectMedia key={project.title} i={i}>
					<ProjectMediaInner to={resolveLink(project)}>
						<StyledImage image={project?.thumbnail?.image} aspectRatio={1.599}/>
						<Title className='sm'>
							{project._type !== 'director' && <ProjectTitle> {project.client}</ProjectTitle>}
							{((project.client != project.projectName) && (project.client != project.title)) &&
								<ProjectName>{project._type === 'director' ? project.title : project.projectName}</ProjectName>
							} 
						</Title>
					</ProjectMediaInner>
				</ProjectMedia>
			))}
		</Wrap>
	)
}

const Wrap = styled.div`
	padding-top: 120px;
`
const ProjectMedia = styled.div`
`

const ProjectMediaInner = styled(Link)``

const StyledImage = styled(Image)`
`
const Title = styled.h2`
	padding: 10px 20px 26px; 
	color: white;
	display: flex;
	justify-content: space-between;
`
const ProjectTitle = styled.div`
	
`
const ProjectName = styled.div`
	
`
ProjectGridMobile.propTypes = {
	className: PropTypes.string,
	data: PropTypes.object,
	bgColor: PropTypes.string,
}

export default ProjectGridMobile